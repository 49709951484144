function contactFormComponent() {
    $.getScript('https://www.google.com/recaptcha/api.js');
}

function contactFormRecaptcha() {
    const form = $('.contact-form').get(0);
    if (form.isValid()) {
        form.submit();
    }
}

function resetCaptcha() {
    grecaptcha.reset();
}