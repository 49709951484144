$(document).ready(function() {

  if (isSafari()) {
    $('#sort-options p').attr('style', 'width: 101%'); // Workaround for safari's subpixel rounding
  }

  var currencySelected = "";
  var languageSelected = "";

  var search = unescape(getUrlParam('search'));
  search = search.replace('+', ' ');
  if (search != "undefined") {
    $('#search-keyword').html(search);
    $('#search-row').removeClass('hide');
    $('#search-ok').html(search);
    $('.no-search-found').removeClass('hide');
  } else {
    $('.no-products-found').removeClass('hide');
  }
  
  bindChangeCurrencyEvent('.js-change-currency');
  bindChangeCurrencyEvent('.subsubmenu-item.currency');

  function bindChangeCurrencyEvent(selector) {
    $(selector).on('click', function () {
      $(selector).removeClass('selected');
      $(this).addClass('selected');
      currencySelected = $(this).find('.codeCurrency').html();
      document.cookie = "currency=" + currencySelected + "; path=/";
      window.location.reload();
    });
  }

  bindChangeLanguageClickEvents('.languages-popup');
  bindChangeLanguageClickEvents('.subsubmenu-item.language');

  function bindChangeLanguageClickEvents(selector) {
    $(selector).on('click', function () {
      $(selector).removeClass('selected');
      $(this).addClass('selected');
      languageSelected = $(this).attr('url');

      if (languageSelected == 'it') {
        countdown.setLabels(
          '| secondo| minuto| ora',
          '| secondi| minuti| ore',
          ' ',
          ' ',
          '');
      }

      window.location.href = languageSelected;
    });
  }

  if (typeof hotspots !== "undefined") {
    for (var i= 0; i < hotspots.length; i++) {
        hotspots[i]();
    }
  }

  $('.product-slider-best').slick({
    dots: true,
    arrows: true,
    infinite: true,
    speed: 300,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          autoplay: true,
          autoplaySpeed: 2000,
          dots: true
        }
      },
      {
        breakpoint: 600,
        settings: {
          autoplay: true,
          autoplaySpeed: 2000,
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2,
          autoplay: true,
          autoplaySpeed: 2000,
          slidesToScroll: 2
        }
      }
      // You can unslick at a given breakpoint now by adding:
      // settings: "unslick"
      // instead of a settings object
    ]
  });

  $('#best-brands').slick({
    infinite: true,
    autoplay: true,
    slidesToShow: 5,
    slidesToScroll: 1,
    pauseOnFocus: false,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        arrows: false,
        settings: {
            slidesToShow: 3
        }
      },
      {
        breakpoint: 640,
        arrows: false,
        settings: {
            slidesToShow: 2
        }
      }
    ]
  });

  var slidesPerView = 1.5;
  var spaceBetween = 10;

  if (Foundation.MediaQuery.is('large')) {
    slidesPerView = 3;
    spaceBetween = 30;
  } else if (Foundation.MediaQuery.is('medium')) {
    slidesPerView = 2.5;
    spaceBetween = 20;
  } else {
    slidesPerView = 1.5;
    spaceBetween = 0;
  }


  var mainslider = new Swiper('#main-slider', {
    speed: 3000,
    autoplay: true,
    slidesPerView: 1,
    mousewheel: {
      forceToAxis: true,
    invert: true,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    }
  });


  // Hash handler
  if (window.location.hash) {
    hashHandler(window.location.hash);
  }

  $(window).on('hashchange', function(event) {
    event.preventDefault();
    hashHandler(window.location.hash);
  });


  // Form input label ids
  $('form').each(function(idx) {
    let inputsWithLabels = $(this).find('input, textarea').toArray().filter(input => $(input).siblings('label').length > 0 && !$(input).attr('id'));
    inputsWithLabels.forEach(input => {
      let name = $(input).attr('name');
      $(input).attr('id', `${name}-${idx}`);
      $(input).siblings('label').attr('for', `${name}-${idx}`);
    });
  });
});

function isSafari() {
  return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
}

function hashHandler(hash) {
  try {
    let position = $(hash).offset().top - $('header').outerHeight();
    let waitForLogo = setInterval(function() {
      if ($('header img').toArray().every(img => img.complete)) {
        $(window).scrollTop(position);
        clearInterval(waitForLogo);
      }
    }, 200);
  }
  catch {}
}